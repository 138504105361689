import axiosClient from '@/axios'

class LoginService {
  login(authentication) {
    return axiosClient.post('/api/v2/authentication/log-in', authentication, {
      errorHandle: true
    })
  }

  signIn(authentication) {
    return axiosClient.post('/api/v2/authentication/sign-in', authentication, {
      errorHandle: true
    })
  }

  loginByCompany(companyId) {
    return axiosClient.patch(`/api/v2/authentication/company/${companyId}`, {
      errorHandle: true
    })
  }

  logoff() {
    return axiosClient.delete('/api/v2/authentication/log-off')
  }

  forgotPassword(email) {
    return axiosClient.post(`/api/v2/authentication/forgot-password/${email}`)
  }

  updatePassword(updatePassword = {}) {
    const { code, newPassword } = updatePassword
    return axiosClient.patch(
      `/api/v2/authentication/recovery-password/${code}`,
      {
        newPassword
      }
    )
  }
}

export default new LoginService()
