import Header from '@/shared/components/organisms/Header/Header.vue'

import { routeExistsInModule } from '../guards/router.guard'

export default [
  {
    path: 'otb-plan',
    components: {
      authenticated: () =>
        import('@/views/modules/Planning/OTBPlan/OTBPlan.vue')
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'OTBPlan',
        components: {
          header: Header,
          otbPlan: () =>
            import('@/views/modules/Planning/OTBPlan/Filter/Filter.vue')
        },
        meta: {
          title: 'Plano OTB',
          requireAuthentication: true
        }
      },
      {
        path: 'spreadsheet',
        name: 'OTBPlanSheet',
        components: {
          header: () =>
            import('@/shared/components/plann-sheet/Toolbar/Toolbar.vue'),
          otbPlan: () =>
            import(
              '@/views/modules/Planning/OTBPlan/Spreadsheet/Spreadsheet.vue'
            )
        },
        meta: {
          title: 'Plano OTB',
          requireAuthentication: true
        }
      }
    ]
  },
  {
    path: 'store-plan',
    components: {
      authenticated: () =>
        import('@/views/modules/Planning/StorePlan/StorePlan.vue')
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'StorePlan',
        components: {
          header: Header,
          storePlan: () =>
            import('@/views/modules/Planning/StorePlan/Filter/Filter.vue')
        },
        meta: {
          title: 'Plano de Lojas',
          requireAuthentication: true
        }
      },
      {
        path: 'spreadsheet',
        name: 'StorePlanSheet',
        components: {
          header: () =>
            import('@/shared/components/plann-sheet/Toolbar/Toolbar.vue'),
          storePlan: () =>
            import(
              '@/views/modules/Planning/StorePlan/Spreadsheet/Spreadsheet.vue'
            )
        },
        meta: {
          title: 'Plano de Lojas',
          requireAuthentication: true
        }
      }
    ]
  },
  {
    path: 'import-files',
    name: 'ImportFiles',
    components: {
      header: Header,
      authenticated: () =>
        import('@/views/modules/Planning/ImportFiles/ImportFiles.vue')
    },
    meta: {
      title: 'Importar Arquivos',
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    }
  }
]
