<template>
  <div class="menu-user-profile">
    <action-user :actions="['Logout', 'ChangeCompany']"></action-user>

    <div class="content-user" @click="toggleProfileSubmenu(), buildEvents()">
      <profile-sidebar :size="AvatarSize.lg"></profile-sidebar>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-dots-vertical"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
        <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
        <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
      </svg>
    </div>

    <profile-submenu
      v-if="isProfileSubmenu"
      :company="getCompany"
    ></profile-submenu>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { AvatarSize } from '@/shared/@constants/avatar.const'
  import { MODULE_COMPANY } from '@/store/@constants/company.const'

  import ActionUser from '../../molecules/ActionUser/ActionUser.vue'
  import ProfileSidebar from '../../molecules/ProfileSidebar/ProfileSidebar.vue'
  import ProfileSubmenu from '../../molecules/ProfileSubmenu/ProfileSubmenu.vue'

  export default {
    name: 'MenuUser',
    components: { ProfileSidebar, ActionUser, ProfileSubmenu },
    data() {
      return {
        AvatarSize,
        isProfileSubmenu: false
      }
    },
    watch: {
      isProfileSubmenu: {
        handler(value) {
          if (value) {
            this.buildEvents()
          }
        }
      },
      $route: {
        handler() {
          this.isProfileSubmenu = false
        }
      }
    },
    computed: {
      ...mapGetters(MODULE_COMPANY, ['getCompany'])
    },
    methods: {
      toggleProfileSubmenu() {
        this.isProfileSubmenu = !this.isProfileSubmenu
      },
      resetProfileSubmenu(event) {
        if (!this.$el.contains(event.target)) {
          this.isProfileSubmenu = false
          this.destroyEvents()
        }
      },
      buildEvents() {
        window.addEventListener('click', this.resetProfileSubmenu)
      },
      destroyEvents() {
        window.removeEventListener('click', this.resetProfileSubmenu)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .menu-user-profile {
    padding-top: 22px;

    .content-user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 14px;
      margin-top: 18px;
      border-top: 1px solid $my-gray-40;
      cursor: pointer;

      svg {
        stroke: $my-text-primary;
      }
    }
  }
</style>
@/shared/@constants/avatar.const
