import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Authenticated from '@/shared/layouts/Authenticated/Authenticated.vue'
import store from '@/store'
import { MODULE_AUTHORIZATION } from '@/store/@constants/authorization.const'
import { MODULE_COMPANY } from '@/store/@constants/company.const'
// import { MODULE_MENU } from '@/store/@constants/menu.const'
import SwaggerApiIntegration from '@/views/modules/SwaggerApiIntegration/SwaggerApiIntegration.vue'

import accountSettingsRoute from './modules/account-settings.route'
import administrativeRoute from './modules/administrative.route'
import authenticationRoute from './modules/authentication.route'
import homeRoute from './modules/home.route'
import integrationsRoute from './modules/integrations.route'
import planningRoute from './modules/planning.route'
import userManagementRoute from './modules/user-management.route'
import versioningRoute from './modules/versioning.route'

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Bootstrap',
    component: Authenticated,
    meta: {
      requireAuthentication: true
    },
    children: [
      ...homeRoute,
      ...accountSettingsRoute,
      ...planningRoute,
      ...versioningRoute,
      ...userManagementRoute,
      ...administrativeRoute,
      ...integrationsRoute
    ]
  },
  ...authenticationRoute,
  {
    path: '/doc/api-integration',
    name: 'SwaggerApiIntegration',
    component: SwaggerApiIntegration,
    meta: {
      requireAuthentication: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { requireAuthentication } = to.meta
  const isAuthenticated = !!store.getters[`${MODULE_AUTHORIZATION}/getToken`]
  const companyStore = store.getters[`${MODULE_COMPANY}/getCompany`]

  const noToken = !isAuthenticated

  const hasTokenNoCompany = isAuthenticated && !companyStore.companyId

  if (requireAuthentication && (noToken || hasTokenNoCompany)) {
    next('/login')
  }
  if (to.name === 'Login' && isAuthenticated && companyStore.companyId) {
    next('/')
  }

  next()
})

export default router
