const state = {
  loading: false
}

const mutations = {
  setLoading: (state, isLoading) => (state.loading = isLoading),
  reset(state) {
    state.loading = false
  }
}

const actions = {
  setLoading({ commit }, isLoading) {
    commit('setLoading', isLoading)
  }
}
const getters = {
  isLoading(state) {
    return state.loading
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
