<template>
  <nav>
    <div v-for="item in modules" :key="item.menuModule" class="groups">
      <div class="name-group">{{ item.menuModuleDescription }}</div>
      <module-groups :links="item.menus"></module-groups>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { MODULE_MENU } from '@/store/@constants/menu.const'

  import ModuleGroups from '../../molecules/ModuleGroups/ModuleGroups.vue'

  export default {
    name: 'OrganismGroupMenu',
    components: { ModuleGroups },
    data() {
      return {
        modules: []
      }
    },
    computed: {
      ...mapGetters(MODULE_MENU, ['getMenu'])
    },
    watch: {
      getMenu: {
        deep: true,
        immediate: true,
        handler(modules) {
          if (modules.length) {
            this.setModules()
          }
        }
      }
    },
    methods: {
      setModules() {
        this.modules = this.getMenu
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  nav {
    @extend .scrollbar;
    padding: 22px 0 !important;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $white;
    transition: all ease-in-out 0.2s;

    @include media-breakpoint-down(xxl) {
      padding-top: 0 !important;
    }

    .groups {
      padding-bottom: 18px;
      transition: all ease-in-out 0.2s;

      @include media-breakpoint-down(xxl) {
        padding-bottom: 0;
      }

      .name-group {
        display: block;
        padding: 0 22px;
        margin-bottom: 8px;
        color: $my-text-primary-60;
        font-size: $font-10;
        text-transform: uppercase;
        transition: all ease-in-out 0.2s;

        @include media-breakpoint-down(xxl) {
          margin-bottom: 8px;
          text-indent: -9999em;
          position: relative;

          &::after {
            content: '';
            display: flex;
            width: 26px;
            height: 1px;
            background-color: $my-gray-40;
          }
        }
      }
    }
  }
</style>
