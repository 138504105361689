const state = {
  zoom: 100
}

const mutations = {
  setZoom(state, zoom) {
    state.zoom = zoom
  },
  reset(state) {
    state.zoom = 100
  }
}

const actions = {
  setZoom({ commit }, zoom) {
    commit('setZoom', zoom)
  }
}

const getters = {
  getZoom(state) {
    return state.zoom
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
