<template>
  <div class="profile-submenu">
    <figure>
      <img
        :src="`data:image/png;base64,${company.image}`"
        :alt="company.name"
      />
    </figure>

    <profile-sidebar :column="true" :size="60" :email="true"></profile-sidebar>

    <action-user
      class="actions-user"
      :actions="['Logout', 'AccountSetup']"
    ></action-user>
  </div>
</template>

<script>
  import { AvatarSize } from '@/shared/@constants/avatar.const'
  import { ProfileTypeDescription } from '@/shared/@constants/profiles.const'

  import ActionUser from '../../molecules/ActionUser/ActionUser.vue'
  import ProfileSidebar from '../ProfileSidebar/ProfileSidebar.vue'

  export default {
    name: 'ProfileSubmenu',
    components: { ProfileSidebar, ActionUser },
    props: {
      user: {
        type: Object,
        default: () => ({})
      },
      company: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        AvatarSize
      }
    },
    methods: {
      getProfileName() {
        return ProfileTypeDescription.get(this.user.profile)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .profile-submenu::v-deep {
    @extend .shadow-lg;
    width: 290px;
    padding-top: 85px;
    border-radius: $my-border-radius;
    border: 1px solid $my-gray-40;
    background-color: $white;
    overflow: hidden;
    position: fixed;
    bottom: 74px;
    left: 22px;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 110px;
      background-color: $my-gray-20;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        max-width: 160px;
      }
    }

    .actions-user {
      padding: 18px 0;
    }

    .box-user {
      margin: 0;
    }

    .box-user,
    .link span {
      opacity: 1;
    }
  }
</style>
@/shared/@constants/avatar.const
