export default {
  inserted(el) {
    const calculatedAndSetMaxHeight = () => {
      const calculateOffsetTop = element => {
        let offsetTop = 0
        while (element) {
          const hasAttribute = element.hasAttribute('max-height-params')
          offsetTop = hasAttribute
            ? Number(element.getAttribute('max-height-params'))
            : 0
          element = !hasAttribute ? element.parentElement : null
        }
        return offsetTop
      }

      const offsetTop = calculateOffsetTop(el.parentElement)
      el.style.maxHeight = `${window.innerHeight - offsetTop}px`
    }

    calculatedAndSetMaxHeight()

    window.addEventListener('resize', calculatedAndSetMaxHeight)

    el._onResize = calculatedAndSetMaxHeight
  },
  unbind(el) {
    window.removeEventListener('resize', el._onResize)
    delete el._onResize
  }
}
