<template>
  <router-link
    :to="buildRouteLink"
    :title="label"
    :class="{ active: isRouteActive }"
    class="link"
  >
    <slot></slot>
    <span>{{ label }}</span>
  </router-link>
</template>

<script>
  export default {
    name: 'Link',

    props: {
      path: {
        type: String,
        default: ''
      },
      params: {
        type: Object,
        default: () => ({})
      },
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
    computed: {
      buildRouteLink() {
        return {
          path: this.path,
          name: this.name,
          params: this.params
        }
      },
      isRouteActive() {
        return this.$route.name === this.name
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .link {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 22px;
    color: $my-text-primary;
    font-size: $font-14;
    text-decoration: none;
    stroke: $my-text-primary;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      color: inherit;
      background-color: $my-gray-10;
    }

    &.active,
    &.router-link-active {
      color: $my-color-primary;
      font-weight: 500;
      background-color: $my-color-primary-10;
      position: relative;
      stroke: $my-color-primary;

      &::after {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        background-color: $my-color-primary;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    span {
      margin-left: 12px;

      @include media-breakpoint-down(xxl) {
        opacity: 0;
      }
    }
  }
</style>
