export const LanguageType = {
  portuguese: 0,
  english: 1,
  spanish: 2
}

export const LanguageTypeFlag = new Map([
  [LanguageType.portuguese, 'pt'],
  [LanguageType.english, 'en'],
  [LanguageType.spanish, 'es']
])

export const LanguageTypeDescription = new Map([
  [LanguageType.portuguese, 'Português'],
  [LanguageType.english, 'Inglês'],
  [LanguageType.spanish, 'Espanhol']
])
