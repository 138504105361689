<template>
  <div id="swagger-ui"></div>
</template>

<script>
  import SwaggerUI from 'swagger-ui-dist/swagger-ui-es-bundle'
  import SwaggerUIStandalonePreset from 'swagger-ui-dist/swagger-ui-standalone-preset.js'

  export default {
    name: 'SwaggerApiIntegration',
    mounted() {
      SwaggerUI({
        dom_id: '#swagger-ui',
        url: '/openapi/openapi.json',
        presets: [SwaggerUI.presets.apis, SwaggerUIStandalonePreset],
        tryItOutEnabled: false
      })

      const bodyElement = document.querySelector('body')
      bodyElement.style.overflow = 'visible'
      bodyElement.style.background = 'white'
    }
  }
</script>

<style lang="scss" scoped>
  @import 'swagger-ui/dist/swagger-ui.css';
</style>
