<template>
  <div id="loading" :class="{ __inline: inline }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; display: block"
      width="120px"
      height="120px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="loader"
    >
      <circle
        cx="50"
        cy="50"
        r="30"
        stroke="rgba(NaN, NaN, NaN, 0)"
        stroke-width="10"
        fill="none"
      ></circle>
      <circle
        cx="50"
        cy="50"
        r="30"
        stroke="#7961f2"
        stroke-width="8"
        stroke-linecap="round"
        fill="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1.5384615384615383s"
          values="0 50 50;180 50 50;720 50 50"
          keyTimes="0;0.5;1"
        ></animateTransform>
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="1.5384615384615383s"
          values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
          keyTimes="0;0.5;1"
        ></animate>
      </circle>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: {
      inline: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  #loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    &.__inline {
      width: auto;
      height: auto;
      position: relative;

      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: $my-overlay;
      position: absolute;
      top: 0;
      left: 0;
    }

    .loader {
      position: relative;
      z-index: 101;
    }
  }
</style>
