<template>
  <div id="access">
    <header>
      <h1>
        <img src="~@/assets/logo-my-otb.svg" />
      </h1>
    </header>

    <main>
      <router-view name="access"></router-view>
    </main>
  </div>
</template>

<script>
  export default {
    name: 'Login'
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  #access {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75px;
      background-color: $white;
      position: relative;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      h1 {
        display: flex;
      }
    }

    main {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-height: calc(100vh - 75px);
      padding: 36px;
      overflow-y: auto;

      @include media-breakpoint-down(md) {
        align-items: flex-start;
        padding: 16px;
      }
    }
  }
</style>
