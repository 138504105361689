const state = {
  daily: null
}
const mutations = {
  setDaily: (state, daily) => {
    state.daily = daily
  },
  reset(state) {
    state.daily = null
  }
}
const actions = {
  setDaily: ({ commit }, daily) => {
    commit('setDaily', daily)
  }
}
const getters = {
  getDaily(state) {
    return state.daily
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
