const CompanyFactory = {
  create() {
    return {
      companyId: null,
      name: '',
      image: '',
      limitUser: 0,
      limitIndicator: 0
    }
  }
}

const state = {
  company: { ...CompanyFactory.create() },
  companies: []
}

const mutations = {
  setCompany: (state, company) => {
    state.company = company
  },
  setCompanies: (state, companies) => {
    state.companies = companies
  },
  reset(state) {
    state.company = { ...CompanyFactory.create() }
    state.companies = []
  }
}

const actions = {
  setCompany({ commit }, company) {
    commit('setCompany', company)
  },
  setCompanies({ commit }, companies) {
    commit('setCompanies', companies)
  }
}

const getters = {
  getCompany(state) {
    return state.company
  },
  getCompanies(state) {
    return state.companies
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
