import Header from '@/shared/components/organisms/Header/Header.vue'

import { routeExistsInModule } from '../guards/router.guard'

export default [
  {
    path: 'users',
    components: {
      authenticated: () =>
        import('@/views/modules/UserManagement/Users/Users.vue')
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'Users',
        components: {
          header: Header,
          users: () =>
            import(
              '@/views/modules/UserManagement/Users/ListUsers/ListUsers.vue'
            )
        },
        meta: {
          title: 'Gestão de Usuários',
          requireAuthentication: true
        }
      },
      {
        path: ':id',
        name: 'EditUser',
        components: {
          header: Header,
          users: () =>
            import('@/views/modules/UserManagement/Users/AddUser/AddUser.vue')
        },
        meta: {
          title: 'Gestão de Usuários',
          requireAuthentication: true
        }
      },
      {
        path: 'add-user',
        name: 'AddUser',
        components: {
          header: Header,
          users: () =>
            import('@/views/modules/UserManagement/Users/AddUser/AddUser.vue')
        },
        meta: {
          title: 'Gestão de Usuários',
          requireAuthentication: true
        }
      }
    ]
  },
  {
    path: 'users-group',
    components: {
      authenticated: () =>
        import('@/views/modules/UserManagement/UsersGroup/UsersGroup.vue')
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'UsersGroup',
        components: {
          header: Header,
          groupUsers: () =>
            import(
              '@/views/modules/UserManagement/UsersGroup/ListGroups/ListGroups.vue'
            )
        },
        meta: {
          title: 'Grupo de Usuários',
          requireAuthentication: true
        }
      },
      {
        path: ':id',
        name: 'EditGroup',
        components: {
          header: Header,
          groupUsers: () =>
            import(
              '@/views/modules/UserManagement/UsersGroup/AddGroup/AddGroup.vue'
            )
        },
        meta: {
          title: 'Gestão de Usuários',
          requireAuthentication: true
        }
      },
      {
        path: 'add-group',
        name: 'AddGroup',
        components: {
          header: Header,
          groupUsers: () =>
            import(
              '@/views/modules/UserManagement/UsersGroup/AddGroup/AddGroup.vue'
            )
        },
        meta: {
          title: 'Gestão de Usuários',
          requireAuthentication: true
        }
      }
    ]
  }
]
