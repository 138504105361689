import { LanguageType } from '../@constants/language.const'

export const UserFactory = {
  create() {
    return {
      name: '',
      email: '',
      userId: null,
      profile: null,
      userImage: '',
      language: LanguageType.portuguese
    }
  }
}
