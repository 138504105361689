import { ModuleFactory } from '@/shared/@factories/modules.factory'

const state = {
  ...ModuleFactory.create()
}

const mutations = {
  setModule: (state, moduleCurrent) => {
    state.moduleCurrent = moduleCurrent
  },
  reset(state) {
    state.moduleCurrent = ''
  }
}

const actions = {
  setModule({ commit }, moduleCurrent) {
    commit('setModule', moduleCurrent)
  }
}

const getters = {
  getModule(state) {
    return state.moduleCurrent
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
