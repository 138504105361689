<template>
  <div class="profile-menu" :class="{ column: column }">
    <avatar :path="getUserImage" :size="size" v-if="getUser.userImage">
    </avatar>
    <avatar :size="size" v-else>
      {{ firstLetterNameAndLastName() }}
    </avatar>
    <div class="box-user">
      <p>
        <span>{{ getUser.name }}</span>
      </p>
      <p>
        {{ getProfileName() }}
      </p>
      <p v-if="email">
        {{ getUser.email }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { ProfileTypeDescription } from '@/shared/@constants/profiles.const'
  import { letterMonograms } from '@/shared/helper/letter-monograms.helper'
  import { MODULE_USER } from '@/store/@constants/user.const'

  import Avatar from '../../atoms/Avatar/Avatar.vue'

  export default {
    name: 'ProfileMenu',
    components: { Avatar },
    props: {
      email: { type: Boolean, default: false },
      size: { type: [String, Number] },
      column: { type: Boolean }
    },
    data() {
      return {
        userImage: ''
      }
    },
    computed: {
      ...mapGetters(MODULE_USER, ['getUser']),
      getUserImage() {
        return this.getUser.userImage
          ? `data:image/jpeg;base64,${this.getUser.userImage}`
          : ''
      }
    },
    methods: {
      firstLetterNameAndLastName() {
        return letterMonograms(this.getUser.name)
      },

      getProfileName() {
        return ProfileTypeDescription.get(this.getUser.profile)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .profile-menu {
    display: flex;
    align-items: center;

    .avatar-name {
      display: block;
      border-radius: 100%;
      border: 3px solid $my-gray-40;
      overflow: hidden;
    }

    &.column {
      flex-direction: column;

      .box-user {
        width: 100%;
        padding-top: 12px;

        p,
        span {
          width: 100%;
          height: 18px;
          text-align: center;
        }

        p {
          &:last-child {
            font-size: $font-12;
          }
        }
      }
    }

    .box-user {
      flex: 1;
      width: 100%;
      margin-left: 12px;
      color: $my-text-primary-80;
      font-size: $font-14;
      font-weight: 400;

      span {
        display: block;
        width: 110px;
        height: 16px;
        color: $my-text-primary;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      @include media-breakpoint-down(xxl) {
        opacity: 0;
      }

      img {
        margin-right: 8px;
      }
    }
  }
</style>
