import dayjs from 'dayjs'

import { ModuleTypes } from '../@constants/planning.const'

export const CURRENT_YEAR = dayjs().get('year')

export const StoreFilterFactory = {
  create() {
    return {
      periodParam: {
        periodBegin: {
          year: CURRENT_YEAR,
          month: 1
        },
        periodEnd: {
          year: CURRENT_YEAR,
          month: 12
        }
      },
      channelIDs: [],
      productIDs: [],
      valueTypeIDs: [],
      versionID: null,
      isReadOnly: true,
      module: ModuleTypes.StorePlan
    }
  }
}
