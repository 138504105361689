<template>
  <el-form
    :model="formCompany"
    ref="formCompany"
    label-position="top"
    label-width="100px"
    :rules="rules"
    :hide-required-asterisk="true"
  >
    <el-form-item label="Empresas" prop="companyId">
      <el-select v-model="formCompany.companyId" placeholder="Select">
        <el-option
          v-for="item in getCompanies"
          :key="item.companyId"
          :label="item.name"
          :value="item.companyId"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-button
      type="primary"
      native-type="button"
      title="Acessar"
      id="btn-access"
      @click="submitForm('formCompany')"
      :loading="isLoading"
    >
      Confirmar
    </el-button>
  </el-form>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import { SERVER_ERROR_LOGIN } from '@/shared/@constants/message-common.const'
  import loginService from '@/shared/services/login.service'
  import { MODULE_AUTHORIZATION } from '@/store/@constants/authorization.const'
  import { MODULE_COMPANY } from '@/store/@constants/company.const'
  import { MODULE_MENU } from '@/store/@constants/menu.const'

  export default {
    name: 'ChooseCompany',

    data() {
      return {
        isLoading: false,
        formCompany: {
          companyId: null
        },
        rules: {
          companyId: [
            {
              required: true,
              message: 'Por favor, selecione uma empresa',
              trigger: 'change'
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters(MODULE_COMPANY, ['getCompany', 'getCompanies'])
    },

    mounted() {
      this.formCompany.companyId = this.getCompany.companyId
    },

    methods: {
      ...mapActions(MODULE_MENU, ['setMenu']),
      ...mapActions(MODULE_COMPANY, ['setCompany']),
      ...mapActions(MODULE_AUTHORIZATION, ['setToken']),
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            const { companyId } = this.formCompany
            this.sendLoginByCompany(companyId)
          } else {
            return false
          }
        })
      },

      async sendLoginByCompany(companyId) {
        try {
          this.isLoading = true
          const { resultValue } = await loginService.loginByCompany(companyId)
          const { token, companies } = resultValue
          await this.setToken(token)
          const [company] = companies.filter(
            company => company.companyId === companyId
          )
          await this.setCompany(company)
          window.location.href = window.location.origin
        } catch (error) {
          const { data } = error.response
          const message = data?.message
          this.$message.error(message || SERVER_ERROR_LOGIN)
        } finally {
          this.isLoading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .el-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .el-form-item {
      flex: 1;
      width: 100%;

      .el-select {
        width: 100%;
      }
    }
  }
</style>
