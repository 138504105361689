<template>
  <div class="authenticated">
    <main>
      <Sidebar></Sidebar>
      <router-view name="header"></router-view>
      <router-view name="authenticated"></router-view>
    </main>
    <loading v-if="isLoading"></loading>

    <el-dialog
      title="Trocar Empresa"
      :visible.sync="dialogChooseCompany"
      width="30%"
      class="dialog-choose-company"
    >
      <choose-company></choose-company>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Loading from '@/shared/components/atoms/Loading/Loading.vue'
  import ChooseCompany from '@/shared/components/molecules/ChooseCompany/ChooseCompany.vue'
  import Sidebar from '@/shared/components/organisms/Sidebar/Sidebar.vue'
  import eventbusHelper from '@/shared/helper/eventbus.helper'
  import WebsocketMixin from '@/shared/mixins/websocket.mixin'
  import { MODULE_LOADING } from '@/store/@constants/loading.const'

  export default {
    name: 'Authenticated',
    components: { Sidebar, Loading, ChooseCompany },
    mixins: [WebsocketMixin],
    data() {
      return {
        dialogChooseCompany: false
      }
    },
    computed: {
      ...mapGetters(MODULE_LOADING, ['isLoading'])
    },
    mounted() {
      eventbusHelper.on('chooseCompanyModal', this.openChooseCompany)
      this.connectWebSocket()
    },
    methods: {
      openChooseCompany() {
        this.dialogChooseCompany = !this.dialogChooseCompany
      },
      connectWebSocket() {
        this.initialize()
        this.connect()
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .authenticated {
    width: 100%;
    min-height: 100vh;

    main {
      width: calc(100% - #{$my-w-sidebar});
      max-height: 100vh;
      position: relative;
      left: calc(#{$my-w-sidebar});

      @include media-breakpoint-down(xxl) {
        width: calc(100% - #{$my-w-sidebar-mobile});
        left: calc(#{$my-w-sidebar-mobile});
      }
    }
  }
</style>
