import { MenuFactory } from '@/shared/@factories/menu.factory'

const state = {
  menu: MenuFactory.create()
}

const mutations = {
  setMenu(state, menu) {
    state.menu = menu
  },
  reset(state) {
    state.menu = MenuFactory.create()
  }
}

const actions = {
  setMenu({ commit }, menu) {
    commit('setMenu', menu)
  }
}

const getters = {
  getMenu(state) {
    return state.menu
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
