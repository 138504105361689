<template>
  <el-avatar :src="path" :size="size" fit="cover" class="avatar"
    ><slot></slot
  ></el-avatar>
</template>

<script>
  import { AvatarSize } from '@/shared/@constants/avatar.const'

  export default {
    name: 'Avatar',
    props: {
      path: {
        type: String,
        default: ''
      },
      size: {
        type: [String, Number],
        default: AvatarSize.sm
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .avatar::v-deep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $my-color-primary;
    font-size: $font-14;
    border-radius: 100%;
    border: 3px solid $my-gray-40;
    overflow: hidden;
    background-color: $my-color-primary-10;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: $white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    img {
      width: 60px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
