import axiosClient from '@/axios'

class MenuService {
  getMenu() {
    return axiosClient.get(`/api/v2/menu`)
  }

  getMenuTree() {
    return axiosClient.get(`/api/v2/menu/company/tree`)
  }

  loadMenuByCompany() {
    return axiosClient.get('/api/v2/menu/company')
  }

  menuByCompany(companyId) {
    return axiosClient.get(`/api/v2/menu/company/${companyId}`)
  }

  deleteAssociated(menu, companyId) {
    return axiosClient.delete(`/api/v2/menu/${menu}/company/${companyId}`)
  }

  addAssociation(menu, companyId) {
    return axiosClient.post(`/api/v2/menu/${menu}/company/${companyId}`)
  }
}

export default new MenuService()
