<template>
  <ul>
    <li>
      <Link
        path="/account/general-settings"
        name="Account"
        label="Minha Conta"
        v-if="filterActions('AccountSetup')"
      >
        <module-icons module="AccountSetup"></module-icons>
      </Link>
    </li>
    <!-- <li>
      <Link
        path="help-center"
        name="HelpCenter"
        label="Central de Aujda"
        v-if="filterActions('HelpCenter')"
      >
        <module-icons module="HelpCenter"></module-icons>
      </Link>
    </li> -->
    <li v-if="getCompanies.length > 1">
      <button
        class="link"
        v-if="filterActions('ChangeCompany')"
        @click="openChooseCompany"
      >
        <module-icons module="ChangeCompany"></module-icons>
        <span>Trocar Empresa</span>
      </button>
    </li>
    <li>
      <Link
        path="logout"
        name="Logout"
        label="Sair"
        v-if="filterActions('Logout')"
      >
        <module-icons module="Logout"></module-icons>
      </Link>
    </li>
  </ul>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { ProfileType } from '@/shared/@constants/profiles.const'
  import eventbusHelper from '@/shared/helper/eventbus.helper'
  import { MODULE_COMPANY } from '@/store/@constants/company.const'
  import { MODULE_USER } from '@/store/@constants/user.const'

  import Link from '../../atoms/Link/Link.vue'
  import ModuleIcons from '../ModuleIcons/ModuleIcons.vue'

  export default {
    name: 'ActionUser',
    components: {
      Link,
      ModuleIcons
    },
    props: {
      actions: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters(MODULE_USER, ['getUser']),
      ...mapGetters(MODULE_COMPANY, ['getCompanies']),
      isProfileMaster() {
        return this.getUser.profile === ProfileType.master
      }
    },
    methods: {
      filterActions(action) {
        if (!this.actions.length) return true

        return this.actions.filter(item => item === action).length
      },
      openChooseCompany() {
        eventbusHelper.emit('chooseCompanyModal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 22px;
    color: #3d4e62;
    font-size: 14px;
    text-decoration: none;
    stroke: #3d4e62;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .link:hover {
    color: inherit;
    background-color: rgba(181, 186, 191, 0.1);
  }

  .link.active,
  .link.router-link-active {
    color: #7961f2;
    font-weight: 500;
    background-color: #f2f0fe;
    position: relative;
    stroke: #7961f2;
  }

  .link.active::after,
  .link.router-link-active::after {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    background-color: #7961f2;
    position: absolute;
    right: 0;
    top: 0;
  }
  .link span {
    margin-left: 12px;
  }

  @media (max-width: 1199.98px) {
    .link span {
      opacity: 0;
    }
  }

  .dialog-choose-company {
    z-index: 2013;
  }
</style>
