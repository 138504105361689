import Header from '@/shared/components/organisms/Header/Header.vue'

import { routeExistsInModule } from '../guards/router.guard'

export default [
  {
    path: 'business-management',
    components: {
      authenticated: () =>
        import(
          '@/views/modules/Administrative/BusinessManagement/BusinessManagement.vue'
        )
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'BusinessManagement',
        components: {
          header: Header,
          businessManagement: () =>
            import(
              '@/views/modules/Administrative/BusinessManagement/ListBusiness/ListBusiness.vue'
            )
        },
        meta: {
          title: 'Gestão de Empresas',
          requireAuthentication: true
        }
      },
      {
        path: ':id',
        name: 'EditCompany',
        components: {
          header: Header,
          businessManagement: () =>
            import(
              '@/views/modules/Administrative/BusinessManagement/AddCompany/AddCompany.vue'
            )
        },
        meta: {
          title: 'Gestão de Empresas',
          requireAuthentication: true
        }
      },
      {
        path: 'add-company',
        name: 'AddCompany',
        components: {
          header: Header,
          businessManagement: () =>
            import(
              '@/views/modules/Administrative/BusinessManagement/AddCompany/AddCompany.vue'
            )
        },
        meta: {
          title: 'Gestão de Empresas',
          requireAuthentication: true
        }
      }
    ]
  },
  {
    path: 'commercial-business',
    components: {
      authenticated: () =>
        import(
          '@/views/modules/Administrative/CommercialBusiness/CommercialBusiness.vue'
        )
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'CommercialBusiness',
        components: {
          header: Header,
          commercialBusiness: () =>
            import(
              '@/views/modules/Administrative/CommercialBusiness/ListCommercialBusiness/ListCommercialBusiness.vue'
            )
        },
        meta: {
          title: 'Contatos Comerciais',
          requireAuthentication: true
        }
      }
    ]
  },
  {
    path: 'parameters',
    name: 'Parameters',
    redirect: 'parameters/general',
    components: {
      header: Header,
      authenticated: () =>
        import('@/views/modules/Administrative/Parameters/Parameters.vue')
    },
    meta: {
      title: 'Parametrizações',
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: 'general',
        components: {
          parameters: () =>
            import(
              '@/views/modules/Administrative/Parameters/views/General/General.vue'
            )
        },
        meta: {
          title: 'Parametrizações',
          requireAuthentication: true
        }
      },
      {
        path: 'indicators',
        components: {
          parameters: () =>
            import(
              '@/views/modules/Administrative/Parameters/views/Indicators/Indicators.vue'
            )
        },
        meta: {
          title: 'Parametrizações',
          requireAuthentication: true
        }
      }
    ]
  }
]
