import Header from '@/shared/components/organisms/Header/Header.vue'

import { routeExistsInModule } from '../guards/router.guard'

export default [
  {
    path: 'integrations',
    name: 'Integrations',
    components: {
      header: Header,
      authenticated: () =>
        import('@/views/modules/Planning/Integrations/Integrations.vue')
    },
    meta: {
      title: 'Integrações',
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    }
  }
]
