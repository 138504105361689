import store from '@/store'
import { MODULE_MENU } from '@/store/@constants/menu.const'

export const routeExistsInModule = (to, from, next) => {
  const exceptionRoutes = ['Bootstrap', 'Logout']

  const isException = exceptionRoutes.includes(to.name)

  if (isException) return isException

  const moduleMenuStore = store.getters[`${MODULE_MENU}/getMenu`]

  const isExistingModule = Boolean(
    moduleMenuStore.filter(
      module =>
        module.menus.filter(menu => to.fullPath.indexOf(menu.path) > -1)
          .length > 0
    ).length
  )

  return isExistingModule ? next() : next(`/no-access`)
}
