import { PlanSheetFactory } from '@/shared/@factories/plan-sheet.factory'

const state = {
  planSheet: { ...PlanSheetFactory.create() },
  isCalculated: false,
  isCellUpdated: false
}

const mutations = {
  setPlanSheet(state, plannSheet) {
    state.planSheet = plannSheet
  },
  setIsCalculated(state, isCalculated) {
    state.isCalculated = isCalculated
  },
  setIsCellUpdated(state, isCellUpdated) {
    state.isCellUpdated = isCellUpdated
  },
  reset(state) {
    state.planSheet = { ...PlanSheetFactory.create() }
    state.isCalculated = false
    state.isCellUpdated = false
  }
}

const actions = {
  setPlanSheet({ commit }, plannSheet) {
    commit('setPlanSheet', plannSheet)
  },
  setIsCalculated({ commit }, isCalculated) {
    commit('setIsCalculated', isCalculated)
  },
  setIsCellUpdated({ commit }, isCellUpdated) {
    commit('setIsCellUpdated', isCellUpdated)
  }
}

const getters = {
  getPlanSheet(state) {
    return state.planSheet
  },
  getPeriods(state) {
    return state.planSheet.Periods
  },
  getChannels(state) {
    return state.planSheet.Channels
  },
  getProducts(state) {
    return state.planSheet.Products
  },
  getIndicators(state) {
    return state.planSheet.Indicators
  },
  getValueTypes(state) {
    return state.planSheet.ValueTypes
  },
  getPeriodParam(state) {
    return state.planSheet.PeriodParam
  },
  getVersionID(state) {
    return state.planSheet.VersionID
  },
  getExtraVersionID(state) {
    return state.planSheet.ExtraVersionID
  },
  getReadOnly(state) {
    return state.planSheet.IsReadOnly
  },
  getFormattingParam(state) {
    return state.planSheet.FormattingParam
  },
  getIsCalculated(state) {
    return state.isCalculated
  },
  getIsCellUpdated(state) {
    return state.isCellUpdated
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
