import dayjs from 'dayjs'

import { ModuleTypes } from '../@constants/planning.const'

export const CURRENT_YEAR = dayjs().get('year')

export const PlanSheetFilterFactory = {
  create() {
    return {
      periodParam: {
        periodBegin: {
          year: CURRENT_YEAR,
          month: 1
        },
        periodEnd: {
          year: CURRENT_YEAR,
          month: 12
        }
      },
      channelIDs: [],
      productIDs: [],
      valueTypeIDs: [],
      versionID: null,
      extraVersionID: null,
      isReadOnly: true,
      module: ModuleTypes.OTBModule
    }
  }
}

export const PlanSheetFactory = {
  create() {
    return {
      Periods: [],
      Channels: {},
      Products: {},
      Indicators: [],
      ValueTypes: [],
      PeriodParam: {},
      VersionID: null,
      ExtraVersionID: null,
      IsReadOnly: true,
      FormattingParam: {}
    }
  }
}
