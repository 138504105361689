<template>
  <div class="title" :class="type">
    {{ text }}
    <slot></slot>
  </div>
</template>

<script>
  import { TitleTypes } from '@/shared/@constants/title.const'

  export default {
    name: 'Title',
    props: {
      text: {
        type: String
      },
      type: {
        type: String,
        default: TitleTypes.md
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .title {
    color: $my-text-primary;
    font-weight: 500;
    white-space: nowrap;

    &.sm {
      font-size: $font-16;
    }
    &.md {
      font-size: $font-18;
    }
    &.lg {
      font-size: $font-22;
    }
    &.h2 {
      font-size: $font-28;
    }
    &.h1 {
      font-size: $font-32;
    }
  }
</style>
@/shared/@constants/title.const
