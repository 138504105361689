<template>
  <aside class="sidebar">
    <div class="brand">
      <a @click="getFirstMenuRoute">
        <img src="~@/assets/logo-my-otb.svg" width="121px" alt="My OTB" />
      </a>
    </div>

    <modules-menu class="menu"></modules-menu>

    <menu-user></menu-user>
  </aside>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import MenuUser from '@/shared/components/organisms/MenuUser/MenuUser.vue'
  import ModulesMenu from '@/shared/components/organisms/ModulesMenu/ModulesMenu.vue'
  import menuService from '@/shared/services/menu.service'
  import { MODULE_MENU } from '@/store/@constants/menu.const'

  export default {
    name: 'Sidebar',
    components: { ModulesMenu, MenuUser },
    computed: {
      ...mapGetters(MODULE_MENU, ['getMenu'])
    },
    mounted() {
      this.loadModulesMenu()
    },
    methods: {
      ...mapActions(MODULE_MENU, ['setMenu']),
      loadModulesMenu() {
        menuService.getMenuTree().then(response => {
          const { resultValue } = response
          this.setMenu(resultValue).then(() => {
            const { name } = this.$route
            if (name === 'Bootstrap') {
              this.redirectUrl(resultValue)
            }
          })
        })
      },
      getFirstMenuRoute() {
        this.redirectUrl(this.getMenu)
      },
      redirectUrl(modules) {
        const { name } = this.$route
        const [
          {
            menus: [firsMenu]
          }
        ] = modules

        if (name !== firsMenu.name) {
          this.$router.push({ name: firsMenu.name })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: $my-w-sidebar;
    height: 100%;
    border-right: 1px solid $my-gray-40;
    background-color: $white;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    transition: all ease-in-out 0.2s;

    @include media-breakpoint-down(xxl) {
      width: $my-w-sidebar-mobile;

      &:hover::v-deep {
        width: $my-w-sidebar;

        a,
        button {
          span {
            opacity: 1 !important;
          }
        }

        .box-user {
          opacity: 1 !important;
        }

        &:hover {
          .groups {
            .name-group {
              &::after {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .brand {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 22px;

      @include media-breakpoint-down(xxl) {
        padding-right: 20px;
      }

      a {
        display: block;
        width: 121px;
        height: 29px;
        cursor: pointer;

        @include media-breakpoint-down(xxl) {
          overflow: hidden;
        }
      }
    }

    .menu {
      flex: 1;
    }
  }
</style>
