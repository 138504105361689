<template>
  <header>
    <Title :text="pageTitle"></Title>
  </header>
</template>

<script>
  import Title from '../../atoms/Title/Title.vue'

  export default {
    name: 'Header',
    components: { Title },
    data() {
      return {
        pageTitle: ''
      }
    },
    watch: {
      '$route.name': {
        deep: true,
        immediate: true,
        handler() {
          this.pageTitle = this.$router.history.current.meta.title
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_imports.scss';

  header {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 22px !important;
    border-bottom: 1px solid $my-gray-40 !important;
    background-color: $white;
  }
</style>
