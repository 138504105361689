export const VersionFactory = {
  create() {
    return {
      isRealized: false,
      percentFrom: 100,
      versionFromID: 0,
      versionToID: 0
    }
  }
}
