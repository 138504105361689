import { OTBFilterFactory } from '@/shared/@factories/otb-filter.factory'

const state = {
  filter: { ...OTBFilterFactory.create() }
}

const mutations = {
  setFilter(state, filter) {
    state.filter = { ...state.filter, ...filter }
  },
  reset(state) {
    state.filter = { ...OTBFilterFactory.create() }
  }
}

const actions = {
  setFilter({ commit }, filter) {
    commit('setFilter', filter)
  }
}
const getters = {
  getFilter(state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
