import Header from '@/shared/components/organisms/Header/Header.vue'

import { routeExistsInModule } from '../guards/router.guard'

export default [
  {
    path: 'version-control',
    components: {
      authenticated: () =>
        import('@/views/modules/Versioning/VersionControl/VersionControl.vue')
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'VersionControl',
        components: {
          header: Header,
          versionControl: () =>
            import(
              '@/views/modules/Versioning/VersionControl/ListVersion/ListVersion.vue'
            )
        },
        meta: {
          title: 'Controle de Versão',
          requireAuthentication: true
        }
      },
      {
        path: ':id',
        name: 'EditVersion',
        components: {
          header: Header,
          versionControl: () =>
            import(
              '@/views/modules/Versioning/VersionControl/AddVersion/AddVersion.vue'
            )
        },
        meta: {
          title: 'Controle de Versão',
          requireAuthentication: true
        }
      },
      {
        path: 'add-version',
        name: 'AddVersion',
        components: {
          header: Header,
          versionControl: () =>
            import(
              '@/views/modules/Versioning/VersionControl/AddVersion/AddVersion.vue'
            )
        },
        meta: {
          title: 'Controle de Versão',
          requireAuthentication: true
        }
      }
    ]
  },
  {
    path: 'merge-version',
    components: {
      authenticated: () =>
        import('@/views/modules/Versioning/MergeVersion/MergeVersion.vue')
    },
    meta: {
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'MergeVersion',
        components: {
          header: Header,
          mergeVersion: () =>
            import('@/views/modules/Versioning/MergeVersion/Filter/Filter.vue')
        },
        meta: {
          title: 'Mesclar Versões',
          requireAuthentication: true
        }
      },
      {
        path: 'result-preview',
        name: 'MergeVersionSheet',
        components: {
          header: Header,
          mergeVersion: () =>
            import(
              '@/views/modules/Versioning/MergeVersion/Preview/Preview.vue'
            )
        },
        meta: {
          title: 'Mesclar Versões',
          requireAuthentication: true
        }
      }
    ]
  }
]
