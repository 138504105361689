import dayjs from 'dayjs'

const TODAY = dayjs()

export const PeriodFactory = {
  create() {
    return [
      {
        from: {
          year: TODAY.year(),
          month: TODAY.month() + 1
        },
        to: {
          year: TODAY.year() + 1,
          month: TODAY.month() + 1
        }
      }
    ]
  }
}
