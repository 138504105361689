import Access from '@/shared/layouts/Access/Access.vue'

export default [
  {
    path: '/login',
    component: Access,
    children: [
      {
        path: '',
        name: 'Login',
        components: {
          access: () => import('@/views/modules/Authentication/Login/Login.vue')
        }
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        components: {
          access: () =>
            import(
              '@/views/modules/Authentication/ForgotPassword/ForgotPassword.vue'
            )
        }
      },
      {
        path: '/update-password/:token',
        name: 'UpdatePassword',
        components: {
          access: () =>
            import(
              '@/views/modules/Authentication/UpdatePassword/UpdatePassword.vue'
            )
        }
      },
      {
        path: '/first-access/:code',
        name: 'FirstAccess',
        components: {
          access: () => import('@/views/modules/Authentication/Login/Login.vue')
        }
      }
    ]
  },
  {
    path: '/logout',
    component: Access,
    meta: {
      requireAuthentication: true
    },
    children: [
      {
        path: '',
        name: 'Logout',
        components: {
          access: () => import('@/views/modules/Logout/Logout.vue')
        }
      }
    ]
  }
]
