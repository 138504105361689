import { UserFactory } from '@/shared/@factories/user.factory'

const state = {
  user: {}
}

const mutations = {
  setUser: (state, user) => {
    state.user = user
  },
  setLanguage: (state, language) => {
    state.user = { ...state.user, language }
  },
  setUserImage: (state, userImage) => {
    state.user = { ...state.user, userImage }
  },
  reset(state) {
    state.user = { ...UserFactory.create() }
  }
}

const actions = {
  setUser({ commit }, user) {
    commit('setUser', user)
  },
  setLanguage({ commit }, language) {
    commit('setLanguage', language)
  },
  setUserImage({ commit }, userImage) {
    commit('setUserImage', userImage)
  }
}

const getters = {
  getUser(state) {
    return state.user
  },
  getLanguage(state) {
    return state.user.language
  },
  getUserImage(state) {
    return state.user.userImage
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
