import Vue from 'vue'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/pt-br'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import maxHeightDirective from './shared/directives/max-height.directive'
import store from './store'

Vue.config.productionTip = process.env.NODE_ENV === 'development'
Vue.config.performance = true
Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.use(ElementUI, { locale })

import 'element-ui/lib/theme-chalk/index.css'
import './assets/scss/element-variables.scss'

import './assets/scss/main.scss'

Vue.directive('max-height', maxHeightDirective)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
