<template>
  <ul>
    <li
      v-for="link in links"
      :key="`link_${link.name}`"
      :style="{ display: link.menu === 2 ? 'none' : '' }"
    >
      <Link
        :path="link.path"
        :name="link.name"
        :label="link.label"
        :params="link.params"
      >
        <module-icons :module="link.name" class="icon-menu"></module-icons>
      </Link>
    </li>
  </ul>
</template>

<script>
  import Link from '../../atoms/Link/Link.vue'
  import ModuleIcons from '../ModuleIcons/ModuleIcons.vue'

  export default {
    name: 'ModuleGroups',
    components: { Link, ModuleIcons },
    props: {
      links: {
        type: Array,
        default: () => [],
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .icon-menu {
    position: relative;
    left: 2px;
  }
</style>
