export const ProfileType = {
  trial: 1,
  buyer: 2,
  planner: 3,
  executive: 4,
  admin: 5,
  master: 6
}

export const ProfileTypeDescription = new Map([
  [ProfileType.trial, 'Desmonstração'],
  [ProfileType.buyer, 'Comprador'],
  [ProfileType.planner, 'Planejador'],
  [ProfileType.executive, 'Executivo'],
  [ProfileType.admin, 'Administrador'],
  [ProfileType.master, 'Master']
])
