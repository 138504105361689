import { mapGetters } from 'vuex'

import router from '@/router'
import { MODULE_USER } from '@/store/@constants/user.const'

import { EnumWebsocketProccess } from '../@constants/websocket.const'
import { disconnetUserStore } from '../helper/disconnect-user.helper'

const WebsocketMixin = {
  data() {
    return {
      socket: null
    }
  },
  computed: {
    ...mapGetters(MODULE_USER, ['getUser'])
  },
  methods: {
    initialize() {
      const { userId } = this.getUser

      this.socket = new WebSocket(
        `${process.env.VUE_APP_API_SOCKET}?userId=${userId}`
      )
    },

    connect() {
      this.socket.onopen = () => {
        console.log('Connected to WebSocket')
      }

      this.watch()
      this.error()
    },

    watch() {
      this.socket.onmessage = event => {
        const { ResultValue } = JSON.parse(event.data)
        if (ResultValue === EnumWebsocketProccess.authentication) {
          disconnetUserStore(() => {
            this.$message.error('Sessão assumida por outro dispositivo.')
            router.push('/login')
          })
        }
      }
    },

    error() {
      this.socket.onerror = error => {
        console.error('WebSocket error:', error)
      }
    }
  },
  beforeDestroy() {
    this.socket.onclose = () => {
      this.socket = null
      console.warn('WebSocket closed')
    }
    this.socket.close()
  }
}

export default WebsocketMixin
