import axios from 'axios'
import { Message } from 'element-ui'

import router from '@/router'
import { LanguageTypeFlag } from '@/shared/@constants/language.const'
import { SESSION_USER_EXPIRED } from '@/shared/@constants/message-common.const'
import { disconnetUserStore } from '@/shared/helper/disconnect-user.helper'
import store from '@/store'
import { MODULE_AUTHORIZATION } from '@/store/@constants/authorization.const'
import { MODULE_USER } from '@/store/@constants/user.const'

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Transfer-Encoding': 'application/json',
    'content-type': 'application/json; charset=utf-8',
    'Accept-Language': LanguageTypeFlag.get(
      store.getters[`${MODULE_USER}/getLanguage`]
    ),
    'Access-Control-Allow-Origin': '*'
  },
  withCredentials: true
})

axiosClient.interceptors.request.use(config => {
  const { headers } = config
  const TOKEN_AUTH = store.getters[`${MODULE_AUTHORIZATION}/getToken`]

  headers.Authorization = `Bearer ${TOKEN_AUTH || ''}`
  return config
})

axiosClient.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    const TOKEN_AUTH = store.getters[`${MODULE_AUTHORIZATION}/getToken`]

    if (TOKEN_AUTH && error.response && error.response.status === 401) {
      Message.error(SESSION_USER_EXPIRED)
      disconnetUserStore(() => router.push('/login'))
      return
    }

    return Promise.reject(error)
  }
)

export default axiosClient
