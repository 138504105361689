export const SERVER_ERROR_MESSAGE =
  'Algo inesperado aconteceu, tente novamente mais tarde.'
export const SERVER_ERROR_LOGIN =
  'Algo inesperado aconteceu, tente efetuar o login novamente.'
export const NETWORK_ERROR_LOGIN =
  'Verifique sua conexão e tente efetuar o login novamente.'

export const SESSION_USER_EXPIRED =
  'Sua sessão expirou, efetue o login novamente.'

export const MESSAGE_CONFIRM_DIALOG_VERSION_DEACTIVATE =
  'A versão permanecerá em nosso banco de dados por um período de 15 dias, após esse período será excluída definitivamente. Deseja prosseguir?'

export const MESSAGE_CONFIRM_DIALOG_COMPANY_DEACTIVATE =
  'Esta ação resultará na suspensão de todas as atividades relacionadas a esta empresa em nosso sistema. Todos os dados associados serão arquivados e não estarão mais acessíveis.'

export const MESSAGE_CONFIRM_DIALOG_COMPANY_DELETE =
  'Esta ação resultará na remoção definitiva de todos os dados e registros associados a esta empresa em nosso sistema. Esta ação não poderá ser desfeita.'

export const MESSAGE_CONFIRM_DIALOG_USER_DEACTIVATE =
  'Esta ação resultará na suspensão de todas as atividades relacionadas a este usuário em nosso sistema. Todos os dados associados serão arquivados e não estarão mais acessíveis.'

export const MESSAGE_CONFIRM_DIALOG_USER_DELETE =
  'Esta ação resultará na remoção definitiva de todos os dados e registros associados a este usuário. Esta ação não poderá ser desfeita.'

export const MESSAGE_CONFIRM_DIALOG_GROUP_DELETE =
  'Esta ação resultará na remoção definitiva de todos os dados e usuários associados a este grupo. Esta ação não poderá ser desfeita.'
