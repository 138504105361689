import Header from '@/shared/components/organisms/Header/Header.vue'

import { routeExistsInModule } from '../guards/router.guard'

export default [
  {
    path: 'insights',
    name: 'Insights',
    components: {
      header: Header,
      authenticated: () => import('@/views/modules/Home/Insights/Insights.vue')
    },
    meta: {
      title: 'Insights',
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    }
  },
  {
    path: 'dashboard',
    name: 'Dashboard',
    components: {
      header: Header,
      authenticated: () =>
        import('@/views/modules/Home/Dashboard/Dashboard.vue')
    },
    meta: {
      title: 'Dashboard',
      requireAuthentication: true
    },
    beforeEnter: (to, from, next) => {
      return routeExistsInModule(to, from, next)
    }
  }
]
