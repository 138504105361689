import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

import { MODULE_AUTHORIZATION } from './@constants/authorization.const'
import { MODULE_COMPANY } from './@constants/company.const'
import { MODULE_INSIGHTS } from './@constants/insights.const'
import { MODULE_LOADING } from './@constants/loading.const'
import { MODULE_MENU } from './@constants/menu.const'
import { MODULE_MERGE_VERSION } from './@constants/merge-version.const'
import { MODULE_CURRENT } from './@constants/module.const'
import { MODULE_OTB_PLAN } from './@constants/otb-plan.const'
import { MODULE_PLAN_SHEET } from './@constants/plan-sheet.const'
import { MODULE_STORE_PLAN } from './@constants/store-plan.const'
import { MODULE_USER } from './@constants/user.const'
import { MODULE_ZOOM_SCREEN } from './@constants/zoom-screen.const'
import authorizationStore from './modules/authorization.store'
import companyStore from './modules/company.store'
import insightsStore from './modules/insights.store'
import loadingStore from './modules/loading.store'
import menuStore from './modules/menu.store'
import moduleCurrent from './modules/module.store'
import otbPlanStore from './modules/otb-plan.store'
import plannSheetStore from './modules/plann-sheet.store'
import mergeVersionStore from './modules/planning-merge.store'
import storePlanStore from './modules/store-plan.store'
import userStore from './modules/user.store'
import zoomScreenStore from './modules/zoom-screen.store'

const moduleLocal = []
const moduleSession = [
  MODULE_USER,
  MODULE_MENU,
  MODULE_COMPANY,
  MODULE_CURRENT,
  MODULE_LOADING,
  MODULE_OTB_PLAN,
  MODULE_INSIGHTS,
  MODULE_STORE_PLAN,
  MODULE_PLAN_SHEET,
  MODULE_ZOOM_SCREEN,
  MODULE_AUTHORIZATION,
  MODULE_MERGE_VERSION
]

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: moduleLocal
})

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: moduleSession
})

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    reset({ commit }) {
      moduleLocal.forEach(moduleName => {
        commit(`${moduleName}/reset`)
      })

      moduleSession.forEach(moduleName => {
        commit(`${moduleName}/reset`)
      })
    }
  },
  modules: {
    [MODULE_USER]: userStore,
    [MODULE_MENU]: menuStore,
    [MODULE_COMPANY]: companyStore,
    [MODULE_LOADING]: loadingStore,
    [MODULE_CURRENT]: moduleCurrent,
    [MODULE_OTB_PLAN]: otbPlanStore,
    [MODULE_INSIGHTS]: insightsStore,
    [MODULE_STORE_PLAN]: storePlanStore,
    [MODULE_PLAN_SHEET]: plannSheetStore,
    [MODULE_ZOOM_SCREEN]: zoomScreenStore,
    [MODULE_MERGE_VERSION]: mergeVersionStore,
    [MODULE_AUTHORIZATION]: authorizationStore
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin]
})
