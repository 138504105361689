export const MergeTypes = {
  version: 1,
  channel: 2,
  product: 3
}

export const MergeTypesDescription = new Map([
  [MergeTypes.version, 'Versão'],
  [MergeTypes.channel, 'Lojas'],
  [MergeTypes.product, 'Produto']
])
