const state = {
  token: ''
}

const mutations = {
  setToken: (state, token) => {
    state.token = token
  },
  reset(state) {
    state.token = ''
  }
}

const actions = {
  setToken: ({ commit }, token) => {
    commit('setToken', token)
  }
}

const getters = {
  getToken(state) {
    return state.token
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
