<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import { MODULE_LOADING } from './store/@constants/loading.const'

  export default {
    name: 'App',
    methods: {
      ...mapActions(MODULE_LOADING, ['setLoading'])
    },
    watch: {
      $route() {
        this.setLoading(false)
      }
    }
  }
</script>

<style lang="scss">
  #app {
    min-height: 100vh;
  }
</style>
