import Header from '@/shared/components/organisms/Header/Header.vue'

export default [
  {
    path: 'account',
    name: 'Account',
    redirect: '/account/general-settings',
    components: {
      header: Header,
      authenticated: () => import('@/views/modules/Account/Account.vue')
    },
    meta: {
      title: 'Minha Conta',
      requireAuthentication: true
    },
    children: [
      {
        path: 'general-settings',
        components: {
          account: () =>
            import(
              '@/views/modules/Account/views/GeneralSettings/GeneralSettings.vue'
            )
        },
        meta: {
          title: 'Minha Conta',
          requireAuthentication: true
        }
      },
      {
        path: 'indicators',
        components: {
          parameters: () =>
            import(
              '@/views/modules/Administrative/Parameters/views/Indicators/Indicators.vue'
            )
        },
        meta: {
          title: 'Minha Conta',
          requireAuthentication: true
        }
      }
    ]
  }
]
